@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  font-family: "Helvetica Neue", "Open Sans", "sans serif";
}

h1 {
  font-size: xx-large;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: #fefefe;
  overflow-y: hidden;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 0.5px solid black;
  padding: 0;
}
